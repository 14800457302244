// paper & background
$paper: #ffffff;

// primary
$primaryLight: #a3b0fc;
$primaryMain: #1E2D7E;
$primaryDark: #0c0961;
$primary200: #cbd3ff;
$primary800: #2e2a88;

// secondary
$secondaryLight: #fe9a9f;
$secondaryMain: #FE0000;
$secondaryDark: #ab0008;
$secondary200: #fdcccf;
$secondary800: #e4454c;

// success Colors
$successLight: #b9f6d0;
$success200: #6dfca4;
$successMain: #00c264;
$successDark: #00af14;

// error
$errorLight: #ef9a9a;
$errorMain: #f44336;
$errorDark: #c72626;

// orange
$orangeLight: #fbe9e7;
$orangeMain: #ffab91;
$orangeDark: #dd4416;

// warning
$warningLight: #fff8e1;
$warningMain: #ffdd7f;
$warningDark: #e9af00;

// wallet
$walletLight: #eeebf9;
$walletMain: #8b75d7;
$walletDark: #6a4dcb;

// grey
$grey50: #fafafa;
$grey100: #f5f5f5;
$grey200: #eeeeee;
$grey300: #e0e0e0;
$grey500: #9e9e9e;
$grey600: #757575;
$grey700: #616161;
$grey900: #212121;

// background gradient
$backgroundPrimary: linear-gradient(90deg, #2e2a88 0%, #0c0961 100%);
$backgroundSecondary: linear-gradient(90deg, #e4454c 0%, #ab0008 100%);
$backgroundWarning: linear-gradient(90deg, rgba(158, 22, 7, 1) 0%, rgba(237, 102, 12, 1) 100%);
$backgroundSuccess: linear-gradient(90deg, rgba(0, 144, 64, 1) 0%, rgba(46, 223, 2, 1) 100%);
;

// ==============================|| DARK THEME VARIANTS ||============================== //

// paper & background
$darkBackground: #1a223f; // level 3
$darkPaper: #111936; // level 4

// dark 800 & 900
$darkLevel1: #29314f; // level 1
$darkLevel2: #212946; // level 2

// primary dark
$darkPrimaryLight: #e3f2fd;
$darkPrimaryMain: #2196f3;
$darkPrimaryDark: #1e88e5;
$darkPrimary200: #90caf9;
$darkPrimary800: #1565c0;

// secondary dark
$darkSecondaryLight: #d1c4e9;
$darkSecondaryMain: #7c4dff;
$darkSecondaryDark: #651fff;
$darkSecondary200: #b39ddb;
$darkSecondary800: #6200ea;

// text variants
$darkTextTitle: #d7dcec;
$darkTextPrimary: #bdc8f0;
$darkTextSecondary: #8492c4;

// ==============================|| JAVASCRIPT ||============================== //

:export {
    // paper & background
    paper: $paper;

    // primary
    primaryLight: $primaryLight;
    primary200: $primary200;
    primaryMain: $primaryMain;
    primaryDark: $primaryDark;
    primary800: $primary800;

    // secondary
    secondaryLight: $secondaryLight;
    secondary200: $secondary200;
    secondaryMain: $secondaryMain;
    secondaryDark: $secondaryDark;
    secondary800: $secondary800;

    // success
    successLight: $successLight;
    success200: $success200;
    successMain: $successMain;
    successDark: $successDark;

    // error
    errorLight: $errorLight;
    errorMain: $errorMain;
    errorDark: $errorDark;

    // orange
    orangeLight: $orangeLight;
    orangeMain: $orangeMain;
    orangeDark: $orangeDark;

    // warning
    warningLight: $warningLight;
    warningMain: $warningMain;
    warningDark: $warningDark;

    // wallet
    walletLight: $walletLight;
    walletMain: $walletMain;
    walletDark: $walletDark;

    // grey
    grey50: $grey50;
    grey100: $grey100;
    grey200: $grey200;
    grey300: $grey300;
    grey500: $grey500;
    grey600: $grey600;
    grey700: $grey700;
    grey900: $grey900;

    // gradient
    backgroundPrimary: $backgroundPrimary;
    backgroundSecondary: $backgroundSecondary;
    backgroundWarning: $backgroundWarning;
    backgroundSuccess: $backgroundSuccess;

    // ==============================|| DARK THEME VARIANTS ||============================== //

    // paper & background
    darkPaper: $darkPaper;
    darkBackground: $darkBackground;

    // dark 800 & 900
    darkLevel1: $darkLevel1;
    darkLevel2: $darkLevel2;

    // text variants
    darkTextTitle: $darkTextTitle;
    darkTextPrimary: $darkTextPrimary;
    darkTextSecondary: $darkTextSecondary;

    // primary dark
    darkPrimaryLight: $darkPrimaryLight;
    darkPrimaryMain: $darkPrimaryMain;
    darkPrimaryDark: $darkPrimaryDark;
    darkPrimary200: $darkPrimary200;
    darkPrimary800: $darkPrimary800;

    // secondary dark
    darkSecondaryLight: $darkSecondaryLight;
    darkSecondaryMain: $darkSecondaryMain;
    darkSecondaryDark: $darkSecondaryDark;
    darkSecondary200: $darkSecondary200;
    darkSecondary800: $darkSecondary800;
}